import { useState, useEffect, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { message, Modal } from "antd";
import {
  handleExtraActive,
  selectExtras,
  getAllExtras,
  disableMenuItemAdminThunk,
  menuExtrasAdminThunk,
} from "../../redux/slices/products";
import { selectUser } from "../../redux/slices/user";
import CondimentUI from "./components/CondimentUI";
import { disableMenuItemExtraAdmin } from "../../api/Products";

const Condiments = () => {
  const [loading, handleLoading] = useState(true);
  const dataextras = useSelector(selectExtras);
  const dispatch = useDispatch();

  const user = useSelector(selectUser);
  const [visibleEditor,handleVisibleEditor] = useState(false)

  const initialFetch = useCallback(async () => {
    handleLoading(true);
    const response = await dispatch( user.admin ? menuExtrasAdminThunk(user.id) : getAllExtras(user.branch));
    if (response.status !== "success") {
      message.error("¡Hubo un problema!");
    }
    handleLoading(false);
  }, [dispatch, user]);

  useEffect(() => {
    initialFetch();
  }, [initialFetch]);

  const handleExtrasActive = async (idExtra, val, type, branch, cat,userId) => {
    const response = await dispatch(handleExtraActive(idExtra, val, type, branch, cat,userId));
    if (response.status !== "success") {
      message.error("¡Hubo un problema!");
    }
  };
  const confirmVisibility = (sku,mode,active) => {
    Modal.confirm({
      title: `¿${active ? 'Desactivar' : 'Activar'} para todas las sucursales?`,
      okText: "Si",
      cancelText: "No",
      async onOk() {
        const response = await dispatch(disableMenuItemExtraAdmin(user.id,sku,mode))
        initialFetch();
        if (response.status !== "success") {
          message.error("¡Hubo un problema!");
        }
      },
      onCancel() {

      },
    });
  }
  const closeEditor = () => {
    handleVisibleEditor(false)
  }
  const openEditor = () => {
    handleVisibleEditor(true)
  }
  return (
    <CondimentUI
      loading={loading}
      extras={dataextras}
      initialFetch={initialFetch}
      handleExtrasActive={handleExtrasActive}
      confirmVisibility={confirmVisibility}
      visibleEditor={visibleEditor}
      closeEditor={closeEditor}
      openEditor={openEditor}
    />
  );
};

export default Condiments;
