import { DatePicker } from "antd";
import styles from "../styles/KpisUI.module.css";
import cx from "classnames";
const DateFilters = ({ active, handleActiveDay, activeCard,handleRangesDates }) => {
  let filters = [];
  if (activeCard.key !== 6) {
    filters = [
      { key: "0", label: "HOY" },
      { key: "-1", label: "AYER" },
      { key: "-7", label: "ÚLTIMOS 7 DÍAS" },
      { key: "-30", label: "ÚLTIMOS 30 DÍAS" },
      { key: "-60", label: "MES PASADO" },
    ];
  }
  if (activeCard.key === 6) {
    filters = [
      { key: "7", label: "SEMANA ACTUAL" },
      { key: "-30", label: "ÚLTIMOS 30 DÍAS" },
      { key: "-180", label: "ULTIMO SEMESTRE" },
    ];
  }
  return (
    <div className={styles.container}>
      <div className={styles.tabs}>
        {filters.map((item) => (
          <span
            className={cx(styles.tab, active === item.key && styles.activeTab)}
            onClick={() => handleActiveDay(item.key)}
          >
            {item.label}
          </span>
        ))}
      </div>
      <div className={styles.pickers}>
        <DatePicker.RangePicker style={{ width: "225px" }} onChange={handleRangesDates} />
      </div>
    </div>
  );
};

export default DateFilters;
