import { Button, Form, Input } from "antd";
import Close from "../../../components/Close";
import styles from "../styles/ProductsUI.module.css";

const PriceEditor = ({ closeEditor }) => {
  return (
    <div className={styles.editor}>
      <div className={styles.header}>
        <span className={styles.title}>Editar Precio</span>
        <Close action={closeEditor} />
      </div>
      <Form layout="vertical">
        <Form.Item
          name="name"
          className={styles.itemColumn}
          label={<span className={styles.label}>PRECIO</span>}
          rules={[{ required: true, message: "Ingresar precio" }]}
        >
          <Input />
        </Form.Item>
        <Button
          className={styles.submit}
          size="large"
          type="primary"
          htmlType="submit"
        >
          GUARDAR CAMBIOS
        </Button>
      </Form>
    </div>
  );
};

export default PriceEditor;
