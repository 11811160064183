import { useState, useEffect, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { message, Modal } from "antd";
import {
  getAllProducts,
  handleSection,
  selectProducts,
  selectCategories,
  menuCentralAdminThunk,
  disableMenuItemAdminThunk,
} from "../../redux/slices/products";
import { selectUser } from "../../redux/slices/user";
import SuggestsUI from "./components/SuggestsUI";

const Suggests = () => {
  const [loading, handleLoading] = useState(true);
  const products = useSelector(selectProducts);
  const categories = useSelector(selectCategories);
  const dispatch = useDispatch();

  const user = useSelector(selectUser);
  const [visibleEditor,handleVisibleEditor] = useState(false)

  const initialFetch = useCallback(async () => {
    handleLoading(true);
    const response = await dispatch(user.admin? menuCentralAdminThunk(user.id) : getAllProducts(user.branch));
    if (response.status !== "success") {
      message.error("¡Hubo un problema!");
    }
    handleLoading(false);
  }, [dispatch, user]);

  useEffect(() => {
    initialFetch();
  }, [initialFetch]);

  const handleProductSection = async (idProduct, val, type, branch,userId) => {
    const response = await dispatch(handleSection(idProduct, val, type, branch,userId));
    if (response.status !== "success") {
      message.error("¡Hubo un problema!");
    }
  };
  const confirmVisibility = (sku,mode,active) => {
    Modal.confirm({
      title: `¿${active ? 'Desactivar' : 'Activar'} para todas las sucursales?`,
      okText: "Si",
      cancelText: "No",
      async onOk() {
        const response = await dispatch(disableMenuItemAdminThunk(user.id,sku,mode))
        console.log(response)
        initialFetch();

        if (response.status !== "success") {
          message.error("¡Hubo un problema!");
        }
      },
      onCancel() {

      },
    });
  }
  const closeEditor = () => {
    handleVisibleEditor(false)
  }
  const openEditor = () => {
    handleVisibleEditor(true)
  }
  return (
    <SuggestsUI
      loading={loading}
      products={products}
      initialFetch={initialFetch}
      handleProductSection={handleProductSection}
      categories={categories}
      confirmVisibility={confirmVisibility}
      visibleEditor={visibleEditor}
      closeEditor={closeEditor}
      openEditor={openEditor}
    />
  );
};

export default Suggests;
