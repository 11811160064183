import { useState, useEffect, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { message, Modal } from "antd";
import {
  getAllProducts,
  handleActive,
  selectProducts,
  selectCategories,
  menuCentralAdminThunk,
  disableMenuItemAdminThunk,
  importItemsThunk,
} from "../../redux/slices/products";
import { selectUser } from "../../redux/slices/user";
import ProductsUI from "./components/ProductsUI";

const Products = () => {
  const [loading, handleLoading] = useState(true);
  const products = useSelector(selectProducts);
  const categories = useSelector(selectCategories);
  const dispatch = useDispatch();

  const user = useSelector(selectUser);
  const [visibleEditor,handleVisibleEditor] = useState(false)

  const initialFetch = useCallback(async () => {
    handleLoading(true);

    const response = await dispatch(user.admin? menuCentralAdminThunk(user.id) : getAllProducts(user.branch));
   
    if (response.status !== "success") {
      message.error("¡Hubo un problema!");
    }
    handleLoading(false);
  }, [dispatch, user]);

  useEffect(() => {
    initialFetch();
  }, [initialFetch]);

  const handleProductActive = async (idProduct, val, type, branch,userId) => {
    const response = await dispatch(handleActive(idProduct, val, type, branch,userId));
    if (response.status !== "success") {
      message.error("¡Hubo un problema!");
    }
  };

  const confirmVisibility = (sku,mode,active) => {
    Modal.confirm({
      title: `¿${active ? 'Desactivar' : 'Activar'} para todas las sucursales?`,
      okText: "Si",
      cancelText: "No",
      async onOk() {
        const response = await dispatch(disableMenuItemAdminThunk(user.id,sku,mode))
        console.log(response)
        if (response.status !== "success") {
          message.error("¡Hubo un problema!");
        }
       initialFetch();
      },
      onCancel() {

      },
    });
  }
  const closeEditor = () => {
    handleVisibleEditor(false)
  }
  const openEditor = () => {
    handleVisibleEditor(true)
  }

  const importItems = async () => {
    const response = await dispatch(importItemsThunk())
    console.log(response)
    if (response.status !== "success") {
      message.error("¡Hubo un problema!");
    }
  }
  return (
    <ProductsUI
      loading={loading}
      products={products}
      initialFetch={initialFetch}
      handleProductActive={handleProductActive}
      categories={categories}
      confirmVisibility={confirmVisibility}
      visibleEditor={visibleEditor}
      closeEditor={closeEditor}
      openEditor={openEditor}
      importItems={importItems}
    />
  );
};

export default Products;
