import React from 'react'
import Close from '../../../components/Close'
import styles from '../styles/ClientsUI.module.css'
import { Button, Col, DatePicker, Form, Input, Row } from 'antd'
import { handleDUI, handlePhone } from '../../../utils/format'
import moment from 'moment'
const Editor = ({client,closeEditor}) => {
  return (
    <div className={styles.editor}>
        <div className={styles.header}>
            <span className={styles.title}>
                 Editar
            </span>
            <Close action={closeEditor} />
        </div>
        <Form layout='vertical' initialValues={{...client,birthday:moment(client.birthday)}}>
            <Row>
                <Col span={24}>
                <Form.Item name="name" className={styles.itemColumn} label={<span className={styles.label}>NOMBRE</span>} rules={[{required:true,message:"Ingresar nombre"}]}>
                    <Input  />
                </Form.Item>
                <Form.Item name="mobile" className={styles.itemColumn} label={<span className={styles.label}>TELÉFONO</span>} rules={[{required:true,message:"Ingresar teléfono"}]}>
                    <Input  onInput={handlePhone} />
                </Form.Item>
                <Form.Item name="email" className={styles.itemColumn} label={<span className={styles.label}>CORREO</span>} rules={[{required:true,type:'email',message:"Ingresar correo"}]}>
                    <Input  />
                </Form.Item>
                <Form.Item name="dui" className={styles.itemColumn}  label={<span className={styles.label}>DUI</span>} rules={[{required:true,message:"Ingresar DUI"}]}>
                    <Input onInput={handleDUI} />
                </Form.Item>
                <Form.Item name="birthday" className={styles.itemColumn}  label={<span className={styles.label}>FECHA DE CUMPLEAÑOS</span>} rules={[{required:true,message:"Ingresar fecha de cumpleaños"}]}>
                    <DatePicker format={"DD/MM/YYYY"}  />
                </Form.Item>
                </Col>
                <Col span={8}>
                <Form.Item className={styles.itemSubmit}></Form.Item>
                <Button className={styles.submit} size="large" type="primary" htmlType='submit' >GUARDAR CAMBIOS</Button>
                </Col>
            </Row>
        </Form>
    </div>
  )
}

export default Editor