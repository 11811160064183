import { Button, Card, DatePicker, Form, Select } from "antd";
import styles from "../styles/Content.module.css";
import { CartesianGrid, Legend, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";
const data = [
    {
      name: 'am',
      value: 4000,
      anterior: 2400,
      amt: 2400,
    },
    {
      name: 'am',
      value: 3000,
      anterior: 1398,
      amt: 2210,
    },
    {
      name: 'am',
      value: 2000,
      anterior: 9800,
      amt: 2290,
    },
    {
      name: 'am',
      value: 2780,
      anterior: 3908,
      amt: 2000,
    },
    {
      name: 'pm',
      value: 1890,
      anterior: 4800,
      amt: 2181,
    },
    {
      name: 'pm',
      value: 2390,
      anterior: 3800,
      amt: 2500,
    },
    {
      name: 'pm',
      value: 3490,
      anterior: 4300,
      amt: 2100,
    },
  ];
  
const OrdersPerHour = ({branches,isAdmin,onFinishFilterDate,summaryHours}) => {
  return (
    <div  className={styles.cardContent}>
      <Form layout="inline" className={styles.formOrders} onFinish={onFinishFilterDate}>
        <Form.Item name="location" hidden={!isAdmin} >
          <Select
            size="large"
            className={styles.select}
            showSearch
            optionFilterProp="label" 
            options={branches.map((item) => {
              return { value: item.id, label: item.zone };
            })}
            allowClear
            placeholder="Todas las sucursales"
          />
        </Form.Item>
        <Form.Item name="date">
          <DatePicker size="large" />
        </Form.Item>
        <Form.Item>
          <Button type="primary" size="large" htmlType="submit">Buscar</Button>
        </Form.Item>
      </Form>
      <br />
      <Card className={styles.containerTotalOverviewCard}>
        <p className={styles.legendTotalOverview}>TOTAL VENTAS</p>
        <ResponsiveContainer width="100%" height={300}>
          <LineChart
            width={500}
            height={300}
            data={data}
            margin={{
              top: 5,
              right: 30,
              left: 20,
              bottom: 5,
            }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="name" />
            <YAxis />
            <Tooltip />
            <Legend />

            <Line type="monotone" dataKey="value" stroke="#FF671D" />
            
          </LineChart>
        </ResponsiveContainer>
      </Card>
    </div>
  );
};

export default OrdersPerHour;
