import {
  Table,
  Switch,
  Button,
  Spin,
  Tooltip,
  Space,
  Dropdown,
  Menu,
  Modal,
} from "antd";
import { selectUser } from "../../../redux/slices/user";
import { getColumnSearchProps } from "../../../utils/tables";
import Header from "../../../components/HeaderList";
import styles from "../styles/ProductsUI.module.css";
import { Link, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { ReactComponent as MoreDetail } from "../../../assets/icons/more-detail.svg";
import PriceEditor from "./PriceEditor";
const ProductsUI = (props) => {
  const {
    loading,
    products,
    handleProductActive,
    categories,
    confirmVisibility,
    visibleEditor,
    closeEditor,
    openEditor,
    importItems
  } = props;

  const user = useSelector(selectUser);

  const getCategoryName = (role) => {
    const index = categories.findIndex((el) => el.value === role);
    if (index > -1) {
      return categories[index].text;
    }
    return "-";
  };

  const irhacia = useNavigate();
  const goToCondiments = () => {
    irhacia("/productos/salsas");
  };
  const goToSuggets = () => {
    irhacia("/productos/recomendados");
  };

  const columns = [
    /* {
      title: "ID",
      dataIndex: "id",
      align: "center",
    }, */
    {
      title: "Nombre",
      dataIndex: "name",
      ...getColumnSearchProps("name"),
    },
    {
      title: "Descripción",
      dataIndex: "description",
      width: 200,
      render: (val) => (
        <Tooltip placement="bottom" title={val}>
          <span className={styles.description}>{val}</span>
        </Tooltip>
      ),
    },
    {
      title: "Categoría",
      dataIndex: "id_category",
      align: "center",
      filters: categories,
      onFilter: (val, record) => record.id_category === val,
      render: (val) => getCategoryName(val),
    },
    {
      title: "Precio",
      dataIndex: "price",
      align: "center",
      render: (val) => `$${val.toFixed(2)}`,
    },
    {
      title: "SKU",
      dataIndex: "sku",
      ...getColumnSearchProps("sku"),
    },
    {
      title: "Disponibilidad",
      align: "center",
      dataIndex: "take_out",
      filters: [
        {
          text: "Activo",
          value: 1,
        },
        {
          text: "Inactivo",
          value: 0,
        },
      ],
      filterMultiple: false,
      onFilter: (value, record) => record.take_out === value,
      render: (val, record) => (
        <Switch
          checked={val}
          onChange={() =>
            handleProductActive(record.id, val, "take_out", user.branch,user.id) // agregar id de usuario
          }
        />
      ),
    },
    /*  {
      title: "Eat In",
      align: "center",
      dataIndex: "eat_in",
      filters: [
        {
          text: "Activo",
          value: 1,
        },
        {
          text: "Inactivo",
          value: 0,
        },
      ],
      filterMultiple: false,
      onFilter: (value, record) => record.eat_in === value,
      render: (val, record) => (
        <Switch
          checked={val}
          onChange={() => handleProductActive(record.id, val, "eat_in", user.branch)}
        />
      ),
    }, */
  ];
  const options = (
    <Menu>
      <Menu.Item>Actualizar Imagen</Menu.Item>
      <Menu.Item onClick={openEditor}>Actualizar Precio</Menu.Item>
    </Menu>
  );
  const columnsAdmin = [
    {
      title: "SKU",
      dataIndex: "sku",
      ...getColumnSearchProps("sku"),
    },
    {
      title: "Nombre",
      dataIndex: "name",
      ...getColumnSearchProps("name"),
    },
    {
      title: "Categoría",
      dataIndex: "id_category",
      align: "center",
      filters: categories,
      onFilter: (val, record) => record.id_category === val,
      render: (val) => getCategoryName(val),
    },
    {
      title: "Precio",
      dataIndex: "price",
      align: "center",
      render: (val) => `$${val.toFixed(2)}`,
    },
    {
      title: "WEB",
      align: "center",
      dataIndex: "active_web",
      render: (active,record) => (
        <Switch checked={active} onChange={() => confirmVisibility(record.sku, "web",active)} />
      ),
    },
    {
      title: "APP",
      align: "center",
      dataIndex: "active_app",
      render: (active,record) => (
        <Switch checked={active} onChange={() => confirmVisibility(record.sku, "app",active)} />
      ),
    },
    {
      title: "PEYA",
      align: "center",
      dataIndex: "active_peya",
      render: (active,record) => (
        <Switch checked={active} onChange={() => confirmVisibility(record.sku, "peya",active)} />
      ),
    },
    {
      title: "UBER",
      align: "center",
      dataIndex: "active_uber",
      render: (active,record) => (
        <Switch checked={active} onChange={() => confirmVisibility(record.sku, "uber",active)} />
      ),
    },
   /*  {
      title: "Acciones",
      align: "center",
      dataIndex: "id",
      render: () => (
        <Dropdown overlay={options} className={styles.menu}>
          <MoreDetail />
        </Dropdown>
      ),
    }, */
  ];
  return (
    <div className={styles.view}>
      <Header title="Productos" />
      <div className={styles.buttons}>
        <Space style={{ paddingBottom: "1em" }}>
          <Button
            onClick={goToCondiments}
            type="primary"
            size="large"
            className={styles.mainButton}
          >
            CONDIMENTS
          </Button>
          <Button
            onClick={goToSuggets}
            type="primary"
            size="large"
            className="main-button"
          >
            RECOMENDADOS/SUGERIDOS
          </Button>
        </Space>
        {user.admin === 1 && (
          <div className={styles.actions}>
            <Button type="primary" size="large" className={styles.mainButton} onClick={importItems}>
              SINCRONIZAR
            </Button>
          {/*   <Button type="primary" size="large" className={styles.mainButton}>
              DISPONIBILIDAD
            </Button> */}
          </div>
        )}
      </div>
      <Modal
        destroyOnClose
        wrapClassName={styles.modal}
        footer={false}
        visible={visibleEditor}
        width={300}
        closable={false}
        onCancel={closeEditor}
      >
        <PriceEditor closeEditor={closeEditor} />
      </Modal>
    
          <Table
            columns={user.admin === 1 ? columnsAdmin : columns}
            dataSource={products}
            rowKey="id"
            loading={loading}
          />
    </div>
  );
};

export default ProductsUI;
