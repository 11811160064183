import axios from "axios";

const getInstance = async (file) => {
  //const baseURL = "https://test.gobuff.com";
  const baseURL = "https://buffaloapi.expresatebox.io";
  const timeout = 120000;
  //console.log(localStorage.getItem("buffalo_admin"))
  const request = axios.create({
    baseURL,
    timeout,
    headers: {
      "Content-Type": file ? "multipart/form-data" : "application/json",
      country: "sv",
    },
  });

  request.interceptors.request.use(
    async (config) => {
      const token = localStorage.getItem("buffalo_admin");
      if (token) {
        config.headers["Authorization"] = `Bearer ${token}`;
      }
      return config;
    },
    (error) => Promise.reject(error)
  );
  return request;
};

export default getInstance;
