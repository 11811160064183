import { Button, Card, DatePicker, Form, Select, Tooltip } from "antd";
import styles from "../styles/Content.module.css";
import {
  Bar,
  BarChart,
  CartesianGrid,
  Legend,
  Rectangle,
  ResponsiveContainer,
  XAxis,
  YAxis,
} from "recharts";
import moment from "moment"


const TotalOverview = ({branches,isAdmin,onFinishFilterTotalOverview,summaryTotals,userBranch}) => {
  console.log(summaryTotals)
  return (
    <div className={styles.cardContent}> 
      <Form layout="inline" onFinish={onFinishFilterTotalOverview} initialValues={{location:userBranch,year:moment()}} >
        <Form.Item name="location" hidden={!isAdmin}>
          <Select
            size="large"
            className={styles.select}
            showSearch
            optionFilterProp="label" 
            options={branches.map((item) => {
              return { value: item.id, label: item.zone };
            })}
            placeholder="Todas las sucursales"
          />
        </Form.Item>
        <Form.Item name="year">
          <DatePicker.YearPicker size="large" placeholder="Seleccionar año" />
        </Form.Item>
        <Form.Item>
          <Button type="primary" size="large" htmlType="submit">
            Buscar
          </Button>
        </Form.Item>
      </Form>
      <br />
      <Card className={styles.containerTotalOverviewCard}>
        <p className={styles.legendTotalOverview}>TOTAL ÓRDENES</p>
        <ResponsiveContainer width="100%" height={400}>
          <BarChart width={500} height={500} data={summaryTotals}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="name" />
            <YAxis />
            <Tooltip />
            <Legend />

            <Bar
              dataKey="ordenes"
              fill="#FF671D"
              activeBar={<Rectangle fill="#FF671D" stroke="blue" />}
              label={{ position: "centerTop", fill: "#FFF" }}
              color="#FFF"
            />
            <Bar
              dataKey="ventas"
              fill="#585858"
              activeBar={<Rectangle fill="#585858" stroke="blue" />}
              label={{ position: "centerTop", fill: "#FFF" }}
              color="#FFF"
            />
          </BarChart>
        </ResponsiveContainer>
      </Card>
    </div>
  );
};

export default TotalOverview;
