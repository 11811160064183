import { Layout, Menu, Dropdown } from "antd";
import moment from "moment-timezone";
import "moment/locale/es";
import { Link, Routes, Route, useLocation } from "react-router-dom";
import { ReactComponent as Logo } from "../../../assets/logo/logo.svg";
import { ReactComponent as Ordenes } from "../../../assets/navigation/ordenes.svg";
import { ReactComponent as Portada } from "../../../assets/navigation/portada.svg";
import { ReactComponent as Productos } from "../../../assets/navigation/productos.svg";
import { ReactComponent as Cobertura } from "../../../assets/navigation/cobertura.svg";
import { ReactComponent as Contenidos } from "../../../assets/navigation/contenidos.svg";
import { ReactComponent as Ajustes } from "../../../assets/navigation/ajustes.svg";
import { ReactComponent as KpisIcon } from "../../../assets/navigation/kpis.svg";
import { ReactComponent as Clock } from "../../../assets/icons/clock.svg";
import { ReactComponent as Account } from "../../../assets/icons/account.svg";
import { ReactComponent as ChevronDown } from "../../../assets/icons/chevron-down.svg";
import { ReactComponent as ScannerIcon } from "../../../assets/navigation/scanner-icon.svg";
import Monitor from "../../monitor/Main";
import History from "../../history/Main";
import Products from "../../products/Main";
import Contents from "../../contents/Main";
import Settings from "../../settings/Main";
import Branches from "../../branches/Main";
import EmergencyBranch from "../../emergency-branch/Main";

import Frontpage from "../../frontpage/Main";
// import Vehicles from "../../vehicles/Main";
import Users from "../../users/Main";
// import Blogs from "../../blogs/Main";
import Clients from "../../clients/Main";
import Client from "../../client-detail/Main";
// import Pages from "../../pages/Main";
import Ratings from "../../ratings/Main";
import styles from "../styles/DashboardUI.module.css";
import Condiments from "../../condiment/Main";
import Zones from "../../zones/Main";
import Zone from "../../zone-detail/Main";
import ZoneList from "../../zone-list/Main";
import Params from "../../params/Main";

import Vehicles from "../../vehicles/Main";
import DriverDetail from "../../driver-detail/Main";
import Scanner from "../../scanner/Main";
import CamScannerUI from "../../cam-scanner/components/CamScannerUI";
import ScannedListUI from "../../scanned-list/components/ScannedListUI";
import ScannedList from "../../scanned-list/Main";
import PromoUI from "../../promo/components/PromoUI";
import Promo from "../../promo/Main";
import Reports from "../../reports/Main";
import Logs from "../../logs/Main";
import Suggests from "../../suggets/Main";
import Drivers from "../../drivers/Main";
import DriversPause from "../../drivers-pause/Main";
import DriversPauseHistory from "../../drivers-pause-history/Main";
import Notifiations from "../../notifications/Main";
import Kpis from "../../kpis/Main";

const { Header, Content, Sider } = Layout;

const DashboardUI = ({ closeSession, user, isAdmin }) => {
  const location = useLocation();

 // console.log(user);

  const NavItem = ({ to, label, Icon }) => {
    const path = location.pathname.split("/")[1];
    const base = `/${path}`;
    return (
      <Link
        to={to}
        className={`${styles.item} ${
          (base === to ||
            ((path === "historial" || path === "promocion") && to === "/")) &&
          styles.active
        }`}
      >
        <Icon className={styles.icon} />
        <span>{label}</span>
      </Link>
    );
  };

  const menu = (
    <Menu>
      <Menu.Item onClick={closeSession} danger>
        Cerrar sesión
      </Menu.Item>
    </Menu>
  );

  const hasPermission = (type) => {
    return user.rols.includes(type);
  };
   // console.log(user.rols)
  return (
    <Layout className={styles.layout}>
      {isAdmin && (
        <Sider
          breakpoint="lg"
          collapsedWidth="0"
          width={98}
          className={styles.sider}
        >
          <div className={styles.logoWrap}>
            <Logo className={styles.logo} />
          </div>
          <div className={styles.nav}>
            {/* // Icono para Scanner ( Promocion dia del nino )
            {hasPermission("dashboard") && (
              <NavItem to="/home-scanner" label="Scanner" Icon={ScannerIcon} />
            )}
            */}

            {hasPermission("dashboard") && (
              <NavItem to="/" label="Ordenes" Icon={Ordenes} />
            )}
            {hasPermission("cover") && (
              <NavItem to="/portada" label="Portada" Icon={Portada} />
            )}
             {hasPermission("settings") && ( 
              <NavItem to="/notificaciones" label="Notificaciones" Icon={Contenidos} />           
              )}
            {hasPermission("products") && (
              <NavItem to="/productos" label="Productos" Icon={Productos} />
            )}
            {hasPermission("branches") && (
              <NavItem to="/sucursales" label="Sucursales" Icon={Cobertura} />
            )}
            <NavItem to="/reportes" label="Reportes" Icon={Contenidos} />
       {/*    <NavItem to="/kpis" label="KPi’S" Icon={KpisIcon} />  */}
            {hasPermission("settings") && (
              <NavItem to="/ajustes" label="Ajustes" Icon={Ajustes} />
            )}
          </div>
        </Sider>
      )}
      <Layout className={styles.layout}>
        <Header className={styles.header}>
          <div className={styles.headerInfo}>
            <span className={styles.title}>PANEL ADMINISTRATIVO</span>
            <Logo className={styles.logoMobile} />
            <div className={styles.wrapDate}>
              <Clock />
              <span className={styles.date}>
                {moment()
                  .tz("America/El_Salvador")
                  .format("D [de] MMMM  -  hh:mm A")}
              </span>
            </div>
            <Dropdown overlay={menu}>
              <div className={styles.accountWrap}>
                <span className={styles.account}>{user.username}</span>
                <ChevronDown className={styles.chevron} />
                <div className={styles.iconAccount}>
                  <Account />
                </div>
              </div>
            </Dropdown>
          </div>
        </Header>
        <Content className={styles.content}>
          <Routes>
            {hasPermission("dashboard") && (
              <Route path="/">
                <Route path="/" element={<Monitor />} />
                <Route path="historial" element={<History />} />
                <Route path="drivers" element={<Drivers />} />
                <Route path="promocion" element={<Promo />} />
              </Route>
            )}
            {hasPermission("cover") && (
              <Route path="portada" element={<Frontpage />} />
            )}
             {hasPermission("settings") && (
              <Route path="notificaciones" element={<Notifiations />} />
           )}
            {hasPermission("products") && (
              <Route path="productos">
                <Route path="" element={<Products />} />
                <Route path="salsas" element={<Condiments />} />
                <Route path="recomendados" element={<Suggests />} />
              </Route>
            )}
            {hasPermission("branches") && (
              <Route path="sucursales">
                <Route path="" element={<Branches />} />
                <Route
                  path="sucursal-emergencia"
                  element={<EmergencyBranch />}
                />
              </Route>
            )}
            {hasPermission("dashboard") && (
              <Route path="home-scanner">
                <Route path="" element={<Scanner />} />
                <Route path="scanner" element={<CamScannerUI />} />
                <Route path="lista-escaneados" element={<ScannedList />} />
              </Route>
            )}
            <Route path="reportes">
              <Route path="" element={<Contents />} />
              <Route path="tiempos" element={<Reports />} />
              <Route path="logs" element={<Logs />} />
            </Route>
            {/* <Route path="contenidos">
              <Route path="" element={<Contents />} />
              <Route path="tiendas" element={<Branches />} />
              <Route path="blogs" element={<Blogs />} />
              <Route path="paginas" element={<Pages />} />
            </Route> */}
            <Route path="kpis">
              <Route path="" element={<Kpis />} />
            </Route>
            {hasPermission("settings") && (
              <Route path="ajustes">
                <Route path="" element={<Settings />} />
                <Route path="usuarios" element={<Users />} />
                <Route path="zonas">
                  <Route path="" element={<Zones />} />
                  <Route path="new" element={<Zone />} />
                  <Route path="edit">
                    <Route path=":id" element={<Zone />} />
                  </Route>
                  <Route path="listado" element={<ZoneList />} />
                </Route>
                <Route path="drivers">
                  <Route path="" element={<Vehicles />} />
                  <Route path="driver">
                    <Route path="new" element={<DriverDetail />} />
                    <Route path="edit/:id" element={<DriverDetail />} />
                  </Route>
                  <Route path="pausas"  >
                  <Route path="" element={<DriversPause />} />
                  <Route path="historial" element={<DriversPauseHistory />} />
                  </Route>
                </Route>
                <Route path="calificaciones" element={<Ratings />} />
                <Route path="clientes">
                  <Route path="" element={<Clients />} />
                  <Route path=":id" element={<Client />} />
                </Route>
                <Route path="parametros" element={<Params />} />
              </Route>
            )}
          </Routes>
        </Content>
        <div className={styles.powered}>
          <a
            href="https://www.expresateweb.com/"
            target="_blank"
            rel="noreferrer"
          >
            <span>Powered by</span>
            <img alt="" src="https://expresate.io/images/logo.svg" />
          </a>
        </div>
      </Layout>
    </Layout>
  );
};

export default DashboardUI;
