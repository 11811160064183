import styles from "../styles/KpisUI.module.css";
import HeaderList from "../../../components/HeaderList";
import DateFilters from "./DateFilters";
import NavItems from "./NavItems";
import TopProducts from "./TopProducts";
import OrdersArea from "./OrdersArea";
import CustomerTracking from "./CustomerTracking";
import Summary from "./Summary";
import AverageTimes from "./AverageTimes";
import TotalOverview from "./TotalOverview";
import OrdersSales from "./OrdersSales";
import OrdersPerHour from "./OrdersPerHour";

const KpisUI = ({
  activeCard,
  activeDate,
  handleActiveDay,
  handleRangesDates,
  handleActiveCard,
  summary,
  branches,
  products,
  timeAverages,
  isAdmin,
  onFinishFilterTotalOverview,
  summaryTotals,
  summaryOrders,
  onFinishFilterDate,
  summaryHours,
  summaryClients,
  userBranch,
  goToClients
}) => {
  const content = {
    1: <Summary summary={summary} />,
    2: (
      <TopProducts branches={branches} products={products} isAdmin={isAdmin} />
    ),
    3: (
      <AverageTimes
        branches={branches}
        timeAverages={timeAverages}
        isAdmin={isAdmin}
      />
    ),
    /*   4: <OrdersArea />, */
    5: (
      <TotalOverview
        branches={branches}
        isAdmin={isAdmin}
        onFinishFilterTotalOverview={onFinishFilterTotalOverview}
        summaryTotals={summaryTotals}
        userBranch={userBranch}
      />
    ),
    6: <OrdersSales branches={branches} isAdmin={isAdmin} summaryOrders={summaryOrders}/>,
    7: <OrdersPerHour branches={branches} isAdmin={isAdmin} onFinishFilterDate={onFinishFilterDate} summaryHours={summaryHours}/>,
    8: <CustomerTracking summaryClients={summaryClients} handleRangesDates={handleRangesDates} goToClients={goToClients}/>,
  };
  /* 

tiempos promedios

hacer sumatoria total por sucursal cuando filtre por sucursal

pedidos por zona pendiente

filtrar en el frontend por sucursales

reporte de recompras

en boton listado mandar a pantalla de listado similar a listado de usuario
mandar parametro a la api que trae todos los usuarios */

  return (
    <div className={styles.view}>
      <HeaderList title={activeCard.key === 1 ? "KPI's" : activeCard.label} />
      <div className={styles.layout}>
        <div>
          {![5, 7, 8].includes(activeCard.key) && (
            <DateFilters
              active={activeDate}
              handleActiveDay={handleActiveDay}
              activeCard={activeCard}
              handleRangesDates={handleRangesDates}
            />
          )}
          <div className={styles.content}>{content[activeCard.key]}</div>
        </div>
        <NavItems activeCard={activeCard} handleActiveCard={handleActiveCard} />
      </div>
    </div>
  );
};

export default KpisUI;
