import React, { useState } from "react";
import styles from "../styles/Content.module.css";
import { Button, Card, Form, Select } from "antd";
import cx from "classnames";
import { Cell, Pie, PieChart, ResponsiveContainer } from "recharts";

const COLORS = ["#FF671D", "#585858", "#FFF"];

const RADIAN = Math.PI / 180;

const renderCustomizedLabel = ({
  cx,
  cy,
  midAngle,
  innerRadius,
  outerRadius,
  value,
  index,
}) => {
  const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);
  return (
    <text
      x={x}
      y={y}
      fill={index === 2 ? "#585858" : "#FFF"}
      textAnchor={x > cx ? "start" : "end"}
      fontWeight="bold"
      fontSize="25px"
      fontFamily="Arial"
      dominantBaseline="central"
    >
      {value}
    </text>
  );
};

const AverageTimes = ({ branches, timeAverages, isAdmin }) => {
  const [locationSelected, handleLocationSelected] = useState(null);
  const filterByBranch = ({ location }) => {
    handleLocationSelected(location);
  };
  const mapData = (type) => {
    if (!locationSelected && type === "branch") {
      return timeAverages[0]?.branch;
    }
    if (!locationSelected && type === "driver") {
      return timeAverages[0]?.driver;
    }
    const timeAveragesByBranch = timeAverages.filter(
      (item) => item.branch_id === locationSelected
    );

    if (type === "branch") {
      return timeAveragesByBranch[0]?.branch;
    }

    if (type === "driver") {
      return timeAveragesByBranch[0]?.driver;
    }
  };
  return (
    <div className={styles.cardContent}>
      {isAdmin && (
        <Form layout="inline" onFinish={filterByBranch}>
          <Form.Item name="location">
            <Select
              size="large"
              className={styles.select}
              showSearch
              optionFilterProp="label"
              options={branches.map((item) => {
                return { value: item.id, label: item.zone };
              })}
              placeholder="Todas las sucursales"
              allowClear
            />
          </Form.Item>
          <Button type="primary" size="large" htmlType="submit">
            Filtrar
          </Button>
        </Form>
      )}

      <br />
      <Card hoverable>
        <div className={styles.chartLegends}>
          <span className={styles.chartLegend}>
            <span className={styles.chartLegendBox} />
            <p>Aceptación</p>
          </span>
          <span className={styles.chartLegend}>
            <span
              className={cx(
                styles.chartLegendBox,
                styles.chartLegendBoxPreparation
              )}
            />
            <p>En preparación</p>
          </span>
          <span className={styles.chartLegend}>
            <span
              className={cx(
                styles.chartLegendBox,
                styles.chartLegendBoxOnTheWay
              )}
            />
            <p>En camino</p>
          </span>
        </div>
        <div className={styles.charts}>
          <div className={styles.chartContent}>
            <ResponsiveContainer width="70%" height={300}>
              <PieChart width={200} height={400} title="Sucursales">
                <text
                  x="50%"
                  y="3%"
                  textAnchor="middle"
                  dominantBaseline="middle"
                  fontSize={20}
                  fontWeight="bold"
                >
                  Sucursales
                </text>
                <Pie
                  data={mapData("branch")}
                  cx="50%"
                  cy="50%"
                  labelLine={false}
                  label={renderCustomizedLabel}
                  outerRadius={125}
                  fill="#8884d8"
                  dataKey="value"
                >
                  {mapData("branch")?.map((_, index) => (
                    <Cell
                      key={`cell-${index}`}
                      fill={COLORS[index % COLORS.length]}
                    />
                  ))}
                </Pie>
              </PieChart>
            </ResponsiveContainer>
          </div>
          <div className={styles.chartContent}>
            <ResponsiveContainer width="70%" height={300}>
              <PieChart width={200} height={400}>
                <text
                  x="50%"
                  y="3%"
                  textAnchor="middle"
                  dominantBaseline="middle"
                  fontSize={20}
                  fontWeight="bold"
                >
                  Drivers
                </text>
                <Pie
                  data={mapData("driver")}
                  cx="50%"
                  cy="50%"
                  labelLine={false}
                  label={renderCustomizedLabel}
                  outerRadius={125}
                  fill="#8884d8"
                  dataKey="value"
                >
                  {mapData("driver")?.map((_, index) => (
                    <Cell
                      key={`cell-${index}`}
                      fill={COLORS[index % COLORS.length]}
                    />
                  ))}
                </Pie>
              </PieChart>
            </ResponsiveContainer>
          </div>
        </div>
        <p className={styles.legend}>*Tiempos promedios en minutos</p>
      </Card>
    </div>
  );
};

export default AverageTimes;
