import { Button, Card, Form, Select } from "antd";
import styles from "../styles/Content.module.css";
import { CartesianGrid, Legend, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";
const data = [
    {
      name: 'Page A',
      actual: 4000,
      anterior: 2400,
      amt: 2400,
    },
    {
      name: 'Page B',
      actual: 3000,
      anterior: 1398,
      amt: 2210,
    },
    {
      name: 'Page C',
      actual: 2000,
      anterior: 9800,
      amt: 2290,
    },
    {
      name: 'Page D',
      actual: 2780,
      anterior: 3908,
      amt: 2000,
    },
    {
      name: 'Page E',
      actual: 1890,
      anterior: 4800,
      amt: 2181,
    },
    {
      name: 'Page F',
      actual: 2390,
      anterior: 3800,
      amt: 2500,
    },
    {
      name: 'Page G',
      actual: 3490,
      anterior: 4300,
      amt: 2100,
    },
  ];
const OrdersSales = ({branches,isAdmin,summaryOrders}) => {
  return (
    <div className={styles.cardContent}>
      {
        isAdmin &&   <Form layout="inline">
        <Form.Item>
          <Select
            size="large"
            className={styles.select}
            showSearch
            optionFilterProp="label" 
            options={branches.map((item) => {
              return { value: item.id, label: item.zone };
            })}
            placeholder="Todas las sucursales"
            mode="multiple"
          />
        </Form.Item>
        <Form.Item>
          <Button type="primary" size="large">Buscar</Button>
        </Form.Item>
      </Form>
      }
    
      <br />
      <Card>
        <p className={styles.legendTotalOverview}>PEDIDOS Y VENTAS TOTALES</p>
        <ResponsiveContainer width="100%" height={300}>
          <LineChart
            width={500}
            height={300}
            data={data}
            margin={{
              top: 5,
              right: 30,
              left: 20,
              bottom: 5,
            }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="name" />
            <YAxis />
            <Tooltip />
            <Legend />
            <Line
              type="monotone"
              dataKey="anterior"
              stroke="#808080"
              strokeDasharray="5 5"
            />
            <Line
              type="monotone"
              dataKey="actual"
              stroke="#FF671D"
            />
          </LineChart>
        </ResponsiveContainer>
      </Card>
    </div>
  );
};

export default OrdersSales;
