import { Table, Spin, Button, Tag, Form, Input } from "antd";
import { Link } from "react-router-dom";
import Header from "../../../components/HeaderList";
import { ReactComponent as Eye } from "../../../assets/icons/eye.svg";
import { getColumnSearchProps } from "../../../utils/tables";
import styles from "../styles/ClientsUI.module.css";
import moment from "moment";

const ClientsUI = (props) => {
  const { loading, clients, exportData, page, handlePage } = props;

  const columns = [
    {
      title: "Nombre",
      dataIndex: "name",
      ...getColumnSearchProps("name"),
    },
    {
      title: "Teléfono",
      dataIndex: "mobile",
      align: "center",
      ...getColumnSearchProps("mobile"),
    },
    {
      title: "Correo",
      dataIndex: "email",
      align: "center",
      ...getColumnSearchProps("email"),
    },
    {
      title: "Ultima compra",
      dataIndex: "last_purchase",
      align: "center",
      render: (val) => moment(val).format("DD/MM/YY - hh:mma"),
    },
    {
      title: "Estado",
      dataIndex: "active",
      align: "center",
      filters: [
        { value: 1, text: "Activo" },
        { value: 0, text: "Bloqueado" },
      ],
      filterMultiple: false,
      onFilter: (val, record) => !!record.active === !!val,
      render: (val) =>
        val ? (
          <Tag color="success">Activo</Tag>
        ) : (
          <Tag color="error">Bloqueado</Tag>
        ),
    },
    {
      title: "Ver",
      align: "center",
      dataIndex: "id",
      render: (id) => (
        <Link to={`/ajustes/clientes/${id}`} className={styles.watch}>
          <Eye className={styles.eye} />
        </Link>
      ),
    },
  ];
 /*  quitar boton de exportar en detalle de cliente



dar mas prioridad al menu central */

  return (
    <div className={styles.view}>
      <Header back="/ajustes" title="Clientes" />
      <Form layout="vertical" className={styles.antForm}>
       {/*  <Form.Item
          label={<span className={styles.label}>CLIENTE</span>}
          className={styles.itemColumn}
        >
          <Input
            placeholder="Nombre,Teléfono,Correo"
            className={styles.input}
            size="large"
          />
        </Form.Item>
      
        <Form.Item className={styles.itemSubmit} label=" ">
          <Button
            className={styles.submit}
            size="large"
            type="primary"
            htmlType="submit"
          >
            BUSCAR CLIENTE
          </Button>
        </Form.Item> */}
        <Form.Item label=" " className={styles.itemSubmit}>
          <Button
            size="large"
            type="primary"
            onClick={exportData}
            htmlType="button"
            className={styles.submit}
          >
            EXPORTAR
          </Button>
        </Form.Item>
      </Form>
      {loading ? (
        <div className={styles.loader}>
          <Spin size="large" />
        </div>
      ) : (
        <div>
          <Table
            columns={columns}
            dataSource={clients}
            rowKey="id"
            pagination={{
              defaultCurrent: page,
              onChange: handlePage,
              total: 10,
            }}
          />
        </div>
      )}
    </div>
  );
};

export default ClientsUI;
