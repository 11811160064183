import instance from "./Request";

export const getKpiSummary = async (data) => {
  const request = await instance();
  const response = request.post("/kpi-resumen", data).catch((error) => {
    return {
      error,
    };
  });
  return response;
};

export const getKpiProducts = async (data) => {
  const request = await instance();
  const response = request.post("/kpi-productos", data).catch((error) => {
    return {
      error,
    };
  });
  return response;
};

export const getTimeAverages = async (data) => {
  const request = await instance();
  const response = request.post("/kpi-tiempos", data).catch((error) => {
    return {
      error,
    };
  });
  return response;
};

export const getSummaryTotals = async (data) => {
  const request = await instance();
  const response = request.post("/kpi-totales", data).catch((error) => {
    return {
      error,
    };
  });
  return response;
};

export const getSummaryOrders = async (data) => {
  const request = await instance();
  const response = request.post("/kpi-pedidos", data).catch((error) => {
    return {
      error,
    };
  });

  return response;
};

export const getSummaryHours = async (data) => {
  const request = await instance();
  const response = request.post("/kpi-horas", data).catch((error) => {
    return {
      error,
    };
  });

  return response;
};

export const getSummaryClients = async (data) => {
    const request = await instance()
    const response = request.post("/kpi-clientes",data).catch((error)=>{
        return {
            error
        }
    })
    return response
}
