import { Button, Form, Select } from "antd";
import styles from "../styles/Content.module.css";
import cx from "classnames";
import { useState } from "react";
const TopProducts = ({ branches, products, isAdmin }) => {
  const [locationSelected, handleLocationSelected] = useState(null);
  
  const filterByBranch = ({ location }) => {
    handleLocationSelected(location);
  };

  const mapData = () => {
    if (!locationSelected) {
      return products;
    }
    const productsByBranch = products.filter(
      (item) => item.id === locationSelected
    );

    return productsByBranch;
  };

  return (
    <div className={styles.cardContent}>
      {isAdmin && (
        <Form layout="inline" onFinish={filterByBranch}>
          <Form.Item name="location">
            <Select
              className={styles.select}
              size="large"
              placeholder="Todas las sucursales"
              showSearch
              optionFilterProp="label"
              options={branches.map((item) => {
                return { value: item.id, label: item.zone };
              })}
              allowClear
            />
          </Form.Item>
          <Button type="primary" size="large" htmlType="submit">
            Filtrar
          </Button>
        </Form>
      )}

      <br />
      <div className={styles.containerTable}>
        <span className={styles.headerItemTable}>Sucursal</span>
        <span className={styles.headerItemTable}>Posición</span>
        <span className={styles.headerItemTable}>Producto</span>
        <span
          className={cx(styles.headerItemTable, styles.lastHeaderItemTable)}
        >
          Cantidad
        </span>
        {mapData().map((item) => (
          <>
            <span className={styles.bodyCellTable}>
              <p>{item.branch}</p>
            </span>
            <span className={styles.bodyCellTable}>
              <p>{item.quantity}</p>
            </span>
            <span className={styles.bodyCellTable}>
              <p>{item.product}</p>
            </span>
            <span className={styles.bodyCellTable}>
              <p>{item.quantity}</p>
            </span>
          </>
        ))}
        {}
      </div>
    </div>
  );
};

export default TopProducts;
